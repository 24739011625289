import type {
  ChatMessage as ChatMessageStream,
  ChatMessageEvidence,
  ChatMessageEvidence as ChatMessageEvidenceStream,
  ChatResponseForm as ChatResponseFormStream,
} from '@zarn/vendor/dist/chat-service';

import { serializeKey } from 'common/utils/serialize.helpers';

import type {
  ChatMessageElement,
  ChatPayload,
  Evidence,
} from './chatApi.types';
import {
  buildEvidenceLabel,
  deserializeFunctionCallProps,
  deserializeSender,
  serializeBotParams,
  serializeChatContext,
} from './chatApi.utils';

// Serialize

const serializeEvidenceItem = (
  evidenceItem: Evidence
): ChatMessageEvidenceStream => ({
  document_hit_url: evidenceItem.documentHitUrl!,
  text_extract: evidenceItem.textExtract,
  ...serializeKey(evidenceItem, 'anchorText', 'anchor_text'),
  ...serializeKey(evidenceItem, 'explanation'),
  ...serializeKey(evidenceItem, 'uri'),
});

const serializeChatResponseForm = (
  conversationItem: ChatMessageElement
): ChatMessageStream => {
  const chatMessageElement: ChatMessageStream = {
    content: conversationItem.content,
    sender: conversationItem.sender,
    ...(conversationItem.image ? { image_uri: conversationItem.image } : {}),
  };

  if (conversationItem.evidences) {
    chatMessageElement.evidences = conversationItem.evidences.map(
      serializeEvidenceItem
    );
  }

  if (conversationItem.functionCallRequest) {
    chatMessageElement.function_call_request =
      conversationItem.functionCallRequest;
  }

  return chatMessageElement;
};

export const serializeChatStreamPayload = ({
  chatResponseForm,
}: ChatPayload): ChatResponseFormStream => ({
  // @ts-expect-error
  bot_type: chatResponseForm.botType,
  context: serializeChatContext(chatResponseForm.context),
  conversation: chatResponseForm.conversation.map(serializeChatResponseForm),
  ...(chatResponseForm.botParams
    ? { bot_params: serializeBotParams(chatResponseForm.botParams) }
    : {}),
});

// Deserialize

export const getEvidenceDocumentId = (evidence: ChatMessageEvidence) => {
  if ('document_id' in evidence && evidence.document_id) {
    return `${evidence.document_id}`;
  }

  if (evidence.document_hit_url) {
    const url = new URLSearchParams(evidence.document_hit_url);
    if (url.get('property_name') === 'id' && url.get('property_values')) {
      return url.get('property_values');
    }
  }

  return null;
};

export const deserializeEvidenceItem = (
  evidenceItem: ChatMessageEvidence,
  index: number
): Evidence => ({
  documentId: getEvidenceDocumentId(evidenceItem),
  textExtract: evidenceItem.text_extract!,
  ...serializeKey(evidenceItem, 'anchor_text', 'anchorText'),
  ...serializeKey(evidenceItem, 'document_hit_url', 'documentHitUrl'),
  label: buildEvidenceLabel(index),
});

export const deserializeChatStreamMessage = (
  message: ChatMessageStream
): ChatMessageElement => {
  const sender = deserializeSender(message.sender);
  const evidences = message.evidences
    ? message.evidences.map(deserializeEvidenceItem)
    : undefined;

  const result: ChatMessageElement = {
    content: message.content,
    sender,
    ...(message.function_call_request
      ? {
          functionCallRequest: deserializeFunctionCallProps(
            message.function_call_request
          ),
        }
      : {}),
    ...(message.image_uri ? { image: message.image_uri } : {}),
  };

  if (evidences) {
    result.evidences = evidences;
  }

  return result;
};
