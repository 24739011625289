import React, { FC, ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import { ChatMessageSenderEnum } from '@zarn/vendor/dist/search';

import { FunctionCallProperties } from 'api/chatApi/chatApi.types';
import TrackedButton from 'common/components/Buttons/TrackedButton/TrackedButton';
import { MarkdownText } from 'common/components/Markdown/MarkdownText';
import NoteAnnotationHighlightItem from 'common/components/Notes/NoteAnnotationHighlightItem';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useQuerySearchParams } from 'common/hooks/useQuerySearchParams';

import { Message } from '../Message';

import { DynamicContextButtonWithDialog } from './DynamicContext/DynamicContextButtonWithDialog';

export interface ConversationMessageProps {
  content: string;
  documentsHitUrls?: string[] | undefined;
  extra?: ReactNode;
  functionCallRequest?: FunctionCallProperties;
  image?: string | null;
  initials?: string;
  sender: ChatMessageSenderEnum;
}

export const ConversationMessage: FC<ConversationMessageProps> = ({
  content,
  documentsHitUrls,
  extra,
  functionCallRequest,
  image,
  initials,
  sender,
}) => {
  const { t } = useTranslation(['chat']);
  const { setParams } = useQuerySearchParams();

  const openSuggestedDocsModal = () => {
    if (!functionCallRequest) {
      throw new Error('Function call request is required');
    }
    setParams(
      {
        fc: functionCallRequest.name,
      },
      {
        functionCallParams: JSON.stringify(functionCallRequest.params),
      }
    );
  };

  return (
    <Message
      button={
        functionCallRequest ? (
          <TrackedButton
            eventName={TrackEventName.TagOnboardingSuggestedDocsClicked}
            onClick={openSuggestedDocsModal}
          >
            {t(`message.functionButton.label`)}
          </TrackedButton>
        ) : null
      }
      extra={extra}
      initials={initials}
      isContent={!!content}
      sender={sender}
      topExtra={
        documentsHitUrls ? (
          <DynamicContextButtonWithDialog documentsHitUrls={documentsHitUrls} />
        ) : null
      }
    >
      <MarkdownText>{content}</MarkdownText>
      {image && (
        <NoteAnnotationHighlightItem
          contentBody={image}
          contentType={'image'}
        />
      )}
    </Message>
  );
};
