import React, { FC, useState } from 'react';

import InventoryIcon from '@mui/icons-material/Inventory';
import { Dialog, DialogTitle, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TrackedButton from 'common/components/Buttons/TrackedButton';
import CollectionDialogContent from 'common/components/Dialogs/CollectionDialogContent/CollectionDialogContent';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useAllHitsFromUrl } from 'containers/Chat/hooks/useAllHitsFromUrl';
import DocsCollection from 'containers/Docs/DocsCollection';
import { useOrganizeDocIds } from 'containers/Docs/hooks/useOrganizeDocIds';
import RetrievalUnit from 'containers/RetrievalUnit/RetrievalUnit';
import { selectUser } from 'containers/User/user.slice';

interface DynamicContextButtonWithDialogProps {
  documentsHitUrls: string[];
}

export const DynamicContextButtonWithDialog: FC<
  DynamicContextButtonWithDialogProps
> = ({ documentsHitUrls }) => {
  const { t } = useTranslation(['chat']);
  const userSettings = useSelector(selectUser);

  const [isContextDialogOpen, setIsContextDialogOpen] = useState(false);

  const openContextDialog = () => {
    setIsContextDialogOpen(true);
  };

  const closeContextDialog = () => {
    setIsContextDialogOpen(false);
  };

  const { docsData } = useAllHitsFromUrl({ docsUrl: documentsHitUrls });
  const organizeDocIds = useOrganizeDocIds(docsData);

  return (
    <>
      <TrackedButton
        endIcon={<InventoryIcon />}
        eventName={TrackEventName.MessageContextInChatClicked}
        size="small"
        sx={{ color: 'gray' }}
        onClick={openContextDialog}
      >
        {t(`message.context.label`)}
      </TrackedButton>
      <Dialog open={isContextDialogOpen} onClose={closeContextDialog}>
        <DialogTitle>
          <Typography>{t(`message.context.label`)}</Typography>
        </DialogTitle>
        <CollectionDialogContent dividers>
          {docsData && docsData.length > 0 && (
            <DocsCollection organizeDocIds={organizeDocIds}>
              {docsData.map((doc, i) => (
                <RetrievalUnit
                  cardComposition={userSettings?.cardStyle}
                  data={doc}
                  evidenceNumber={i + 1}
                  isEvidence={true}
                  key={doc.document.id}
                  withImage={true}
                />
              ))}
            </DocsCollection>
          )}
        </CollectionDialogContent>
      </Dialog>
    </>
  );
};
